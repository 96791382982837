<template>
  <div class="scans">
    <v-modal-scan :display="dialogScan" @scan="onScanSave" @close="onNewScanClose" />
    <v-modal-scan-details v-model="dialogDetails" :id="selectedScanId" />
    <v-modal-scan-delete v-model="dialogDelete" :id="selectedScanId" />
    <v-modal-scan-email v-model="dialogEmail" :item="selectedItem" @send="onSendEmail" />
    <v-modal-task-progress ref="taskBar" :display="progressDialog" @end="onScanEnd" />

    <v-page-title title="Scans">
      <v-btn
        elevation="0"
        color="blue-grey darken-4"
        small
        dark
        @click="openScanDialog"
        v-show="sites && sites.length > 0 && credits && (credits.remaining_credits > 0 || credits.user_frequency == '∞')">
        <v-icon left small>mdi-plus</v-icon>
        New Scan
      </v-btn>
    </v-page-title>
    <v-page-subtitle title="">
      <v-container class="py-5" style="max-width:1160px;">
        <v-row>
          <v-col class="ml-auto">
            <v-select
              v-model="site"
              :items="sites"
              item-value="id"
              item-text="name"
              hide-details
              dense
              outlined
              label="Filter by site">
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-page-subtitle>
    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row dense align="center">
          <v-col>
            <v-loader v-if="!scans"></v-loader>
            <v-alert light color="white" class="text-center" v-else-if="scans.length == 0">No scans yet.</v-alert>
            <div class="hounddog-table" v-else>
              <v-data-table
                :headers="headers"
                :items="scans"
                :items-per-page="15"
                :sort-desc="true"
                item-key="id"
                sort-by="created_at"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :show-expand="false"
                no-data-text="Loading...">

                <template v-slot:item.expand="{ item }">
                  <span class="text-caption mr-2" v-if="item.attachments">{{ item.attachments.length }}</span>
                  <span class="text-caption mr-2" v-else>0</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small color="blue-grey lighten-1 white--text" elevation="0" v-bind="attrs" v-on="on" @click="toggleExpand(item)">
                        Open
                      </v-btn>
                    </template>
                    <span>Add Additional Documents</span>
                  </v-tooltip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-0 elevation-0" style="border: none">
                    <v-modal-scan-attachment :item="item" @save="onSaveAttachments" />
                  </td>
                </template>

                <template v-slot:item.icon="{}">
                  <v-icon color="#CFD8DC" class="pr-2">mdi-file-document-outline</v-icon>
                </template>

                <template v-slot:item.site_name="{ item }">
                  <span v-if="item.new_site_url">
                    <b>{{ item.new_site_url }}</b>
                    <v-btn absolute right small><v-icon small>mdi-plus</v-icon> Add</v-btn>
                    <span class="text-caption"> ({{ item.id }})</span>
                  </span>
                  <span v-else>
                    <b>{{ item.site_name }}</b>
                    <span class="text-caption"> ({{ item.id }})</span>
                  </span>
                </template>

                <template v-slot:item.branding="{ item }">
                  <div class="gray-background" v-if="item.override_branding != null">
                    <v-company-logo
                      :override="item.override_branding != null ? item.override_branding : item.branding"
                      height="30px"
                      width="100px"
                      style="padding: 0; margin: 0"></v-company-logo>
                  </div>
                </template>

                <template v-slot:item.created_at="{ item }">
                  {{ $date.fullDateTime2(item.created_at) }}
                </template>
                
                <template v-slot:item.download="{ item }">
                  <template v-if="item.report_pdf">
                    <v-btn-toggle mandatory background-color="blue-grey lighten-1" class="ml-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogEmail(item)">
                            <v-icon small color="white">mdi-email-fast</v-icon>
                          </v-btn>
                        </template>
                        <span>Deliver via Email</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" :href="item.report_pdf" target="_blank">
                            <v-icon small color="white">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download Report</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogDelete(item)">
                            <v-icon small color="white">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Scan</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </template>
                  <template v-else>
                    <v-hover v-slot="{ hover }">
                      <v-btn :color="hover ? '#111' : '#111'" block small text class="elevation-0 text-capitalize"> Processing... </v-btn>
                    </v-hover>
                  </template>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { io } from "socket.io-client";
export default {
  name: "Scans.vue",
  data() {
    return {
      expanded: [],
      singleExpand: false,
      selectedScanId: 0,
      selectedItem: {},
      selectedDomain: null,
      dialogDetails: false,
      dialogDelete: false,
      dialogEmail: false,
      dialogScan: false,
      dialogScanRunning: false,
      site: null,
      headers: [
        { text: "", value: "icon", width: "40px" },
        { text: "Site", value: "site_name" },
        {
          text: "Created On",
          value: "created_at",
          width: "180px",
          align: "left",
        },
        {
          text: "Attachments",
          value: "expand",
          width: "12%",
          align: "center",
        },
        {
          text: "",
          value: "download",
          align: "right",
          width: "150px",
        },
      ],
      process: [],
      progressDialog: false,
      processMax: 2,
    };
  },
  computed: {
    scans() {
      return this.$store.getters["scans/all"];
    },
    sites() {
      return this.$store.getters["sites/all"];
    },
    credits() {
      return this.$store.getters["transactions/credits"];
    },
  },
  mounted() {
    if (this.scans) {
      const list = this.scans.filter((r) => r.report_pdf === null);
      if (list && list.length > 0) {
        this.initScan(list);
      }
    }
    this.initSocket();
  },
  methods: {
    toggleExpand(item) {
      const index = this.expanded.indexOf(item);
      if (index === -1) {
        this.expanded.push(item);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    initScan(scanList) {
      if (!scanList || scanList.length === 0) {
        return;
      }

      if (this.process.length > this.processMax) {
        return;
      }

      const newScans = scanList.filter((scan) => !this.process.some((p) => p.id === scan.id));
      const slotsAvailable = this.processMax - this.process.length;
      const itemsToAdd = newScans.slice(0, slotsAvailable).map((r) => ({
        id: r.id,
        name: r.site_name,
        tasks: this.$strings.scanList(),
        progress: 0,
        pdf: r.report_list,
      }));

      this.process = [...this.process, ...itemsToAdd];
      this.progressDialog = this.process.length > 0;

      const manager = this.$refs.taskBar;
      manager.reset();
      manager.itemAdd(this.process);
    },
    initSocket() {
      const manager = this.$refs.taskBar;
      const socket = io(process.env.VUE_APP_API_ENDPOINT);

      socket.on("report-progress", (response) => {
        const { id, progress, url } = response;
        const report = this.process.find((r) => r.id === id);
        
        if (report && progress) {
  
          report.progress = progress;
          manager.itemUpdate(id, progress);

          if (progress === 100) {
            this.process = this.process.filter((p) => p.id !== id);

            const scan = this.scans.find((r) => r.id === id);

            if (scan) {
              const payload = { ...scan, report_pdf: url };
              this.$store.commit("scans/SET_SCAN_COMPLETED", payload);
            }

            setTimeout(async () => {
              manager.itemRemove(id);
            }, 5000);
          }
        }
      });
    },
    onClickOpenDialogEmail(item) {
      const site = this.sites.find((r) => r.id === item.client_id);
      this.selectedItem = { ...item, url: site.url };
      this.dialogEmail = true;
    },
    onClickOpenDialogDelete(item) {
      this.selectedScanId = item.id;
      this.dialogDelete = true;
    },
    onScanRefresh(scanList) {
      if (scanList) {
        const list = scanList.filter((r) => r.report_pdf === null);
        if (list && list.length > 0) {
          this.initScan(list);
        }
      }
    },
    openScanDialog() {
      const slot = this.process.length;
      if (slot >= 2) {
        this.$swal.fire({
          title: "Site Scan",
          text: `Only ${this.processMax} process is allowed simultanously.`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
      this.dialogScan = true;
    },
    onNewScanClose() {
      this.dialogScan = false;
    },
    async onScanSave(data) {
      this.dialogScan = false;
      await this.$store.dispatch("scans/insert", data);
    },
    async onScanStart() {
      this.progressDialog = this.process.length > 0;
      if (this.process && this.process.length > 0) {
        for (let i = 0; i < this.process.length; i++) {
          let task = this.process[i];
          if (task.progress === 0) {
            await this.$store.dispatch("scans/start", { id: task.id });
          }
        }
      }
    },
    async onScanEnd(data) {
      const manager = this.$refs.taskBar;
      manager.itemRemove(data.id);
      const index = this.process.findIndex((process) => process.id === data.id);
      if (index !== -1) {
        this.process.splice(index, 1);
        await this.$store.dispatch("scans/end", {
          id: data.id,
        });
      }
    },
    async onSaveAttachments(data, item) {
      await this.$store.dispatch("scans/updateAttachments", data);
      this.toggleExpand(item);
    },
    async onSendEmail(data) {
      this.dialogEmail = false;
      await this.$store.dispatch("scans/sendReport", data);
    },
  },
  watch: {
    process: {
      handler: function () {
        this.onScanStart();
      },
      deep: true,
    },
    scans(arr) {
      if (arr && arr.length) {
        this.onScanRefresh(arr);
      }
    },
  },
};
</script>

<style lang="scss">
.scans{
  .btn-process:hover {
    background-color: #fff !important;
  }
  .v-data-table__expanded__row{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, .1) !important;
    > td{
      border-top: 1px solid rgba(0, 0, 0, .3) !important;
      border-bottom: 2px solid rgba(0, 0, 0, .3) !important;
      border-left-width: 0px;
      border-right-width: 0px;
      &:first-child{
        border-left: 2px solid rgba(0, 0, 0, .3) !important;
      }
      &:last-child{
        border-right: 2px solid rgba(0, 0, 0, .3) !important;
      }
    }
  }
  .v-data-table__expanded__content {
    box-shadow: none !important;
    &:hover {
      td{
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        // background-color: #fff !important;
      }
    }
    td{
      border-bottom: thin solid rgba(0, 0, 0, .12) !important;
    }
  }
}
</style>
